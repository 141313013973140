<template>
	<v-container fluid>
		<v-container>
			<v-card flex d-flex flex-column>
				<v-card-title>{{ $t('roadshow.title') }}</v-card-title>
<!--				<v-card-subtitle>{{ $t('roadshow.subtitle') }}</v-card-subtitle>-->
				<v-card-text class="justified">
					<v-row dense>
<!--						<v-col class="centered">
							<v-avatar tile size="300">
									<v-img src="pictures/picture/events/roadshow.jpg"></v-img>
							</v-avatar>
						</v-col>
-->
						<v-col class="centered">
<!--							<v-avatar tile class="profile" size="300">-->
									<v-img src="pictures/picture/events/training.png" style="height:300px;"></v-img>
<!--							</v-avatar>-->
						</v-col>
					</v-row>
					<v-row><v-col>{{$t('roadshow.subtitle')}}</v-col></v-row>
					<v-row><v-col><h4>ZOZNAM NADCHÁDZAJÚCICH ŠKOLENÍ A PREZENTÁCIÍ:</h4></v-col></v-row>
					
					<v-row v-if="!auth">
						<v-col style="color:red;text-align:justify;">
							Prihlasovanie na školenia a prezentácie vyžaduje používateľské konto na webovej stránke SECTRO.SK.
						</v-col>
					</v-row>
					<v-row v-if="!auth">
						<v-col align="center" jistify="space-around">
							<v-btn color="red" outlined route to="/login"><v-icon left>mdi-login</v-icon>prihlásiť</v-btn>&nbsp;
							<v-btn outlined color="red" route to="/registration"><v-icon left>mdi-account-plus</v-icon>registrovať</v-btn>
						</v-col>
					</v-row>
					<v-row>
					<v-col v-if="eves.length == 0">
						<div>Momentálne nie sú naplánované žiadne školenia a prezentácie. Nezabudnite túto sekciu navštíviť aj pri najbližšej návšteve stránky SECTRO.SK</div>
					</v-col>
					<v-col v-else class="d-flex flex-column" v-for="eve in eves" :key="eve['id']" cols="12" sm="6" md="4" lg="3">
						<v-card class="flex d-flex flex-column" outlined tile elevation="24">
                <v-card-title class="headline">{{eve.is_roadshow}}</v-card-title>
                <v-card-subtitle class="textik">
									<v-simple-table>
										<tr>
											<td><v-icon>mdi-calendar-clock</v-icon></td>
											<td>{{ eve.date }}</td>
										</tr>
										<tr>
											<td><v-icon>mdi-city</v-icon></td>
											<td>{{eve.location}}</td>
										</tr>
										<tr>
											<td><v-icon>mdi-map-marker</v-icon></td>
											<td><a :href="eve.place_url">{{ eve.place }}</a></td>
										</tr>
										<tr>
											<td><v-icon>mdi-account-box-outline</v-icon></td>
											<template v-if="auth">
											<td v-if="eve.status == '-'" style="color:red">Nie ste prihlásený</td>
											<td v-else style="color:green">Ste prihlásený</td>
											</template>
											
											<template v-else>
											<td style="color:red;">Prihlasovanie len pre používateľov SECTRO.SK</td>
											</template>
										</tr>
										<tr>
											<td style="vertical-align: top;"><v-icon>mdi-information-variant</v-icon></td>
											<td style="text-align: justify" v-html="eve.note"></td>
										</tr>
									</v-simple-table>
								</v-card-subtitle>
								<v-spacer></v-spacer>
								<v-card-actions v-if="auth && is_modifiable(eve.date)">
												<v-btn v-if="eve.status=='reg'" @click="unregister_from_event(eve.id)" block><v-icon left>mdi-close</v-icon>ODHLÁSIŤ</v-btn>
												<v-btn v-else @click="register_to_event(eve.id)" block><v-icon left>mdi-check</v-icon>PRIHLÁSIŤ</v-btn>
								</v-card-actions>
			</v-card>
			</v-col>
	</v-row>
</v-card>
    </v-container>
  </v-container>

</template>

<script>
import ApiService from "@/services/api_service"

	export default {
		data: () => ({
			current_user_id: -1,
			eves: [] 
		}),
		methods: {
			register_to_event(id) {
					var formData = new FormData()
          formData.append('user_id', this.current_user_id)
					formData.append('event_id', id)
				ApiService.post('/education/reg', formData)
				.then(response => {
					console.log(response);
					console.log(response.data.status)
					if(response.data.status == 'DONE') {
						for(var i=0;i<this.eves.length;i++) {
							if(this.eves[i].id == id) {this.eves[i].status='reg';break;}
						}
					}
					//this.$router.push('/');
				})
				.catch(() => {
				});
			},
			is_modifiable(date_of_event) {
				var date_data = date_of_event.split('.')
				var date_today = new Date();
				var date_event = new Date([date_data[2], date_data[1], date_data[0]].join('-'));
				return date_today < date_event
			},
      unregister_from_event(id) {
				var formData = new FormData()
				formData.append('user_id', this.current_user_id)
				formData.append('event_id', id)
				ApiService.post('/education/unreg', formData)
				.then(response => {
					console.log(response)	
					console.log(response.data.status)
					if(response.data.status == 'DONE') {
						for(var i=0;i<this.eves.length;i++) {
							if(this.eves[i].id == id) {this.eves[i].status='-';break;}
						}
					}
			})
				.catch(() => {})
      }
		},
		mounted() {
			console.log(this.current_user_id)
			this.current_user_id = this.$store.getters.get_user_id
			console.log(this.current_user_id)
			this.auth = this.$store.getters.is_authenticated
			ApiService.get('/education/eves/').then((response) => {
      this.eves = response.data;
			for(var i=0;i <= this.eves.length; i++) {
				if(this.eves[i].is_roadshow == 0) this.eves[i].is_roadshow = this.$t('roadshow.presentation');
				else this.eves[i].is_roadshow = this.$t('roadshow.training');
			}
    }).catch(() => {});

		}
	}
</script>

<style scoped>
.justified {
  text-align: justify;
}
.centered {
	text-align: center;
}
.limited {
	max-width: 50%;
}
.hover_magic:hover {
	background-color: lightgrey;
}
td {
	padding: 5px;
}
</style>
